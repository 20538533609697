import { FunctionComponent, HTMLAttributes } from 'react'
import {
  mapBulmaProps,
  BulmaProps,
  HTMLDefaultProps,
  HTMLDivProps,
  HTMLParagraphProps,
} from './base'

export interface ContainerProps extends HTMLDivProps, BulmaProps {}

export const Container: FunctionComponent<ContainerProps> = (props) => (
  <div {...mapBulmaProps(props, 'container')} />
)

export interface SectionProps extends HTMLDefaultProps, BulmaProps {}

export const Section: FunctionComponent<SectionProps> = (props) => (
  <section {...mapBulmaProps(props, 'section')} />
)

export interface FooterProps
  extends HTMLAttributes<HTMLDivElement>,
    BulmaProps {}

export const Footer: FunctionComponent<FooterProps> = (props) => (
  <div {...mapBulmaProps(props, 'footer')} />
)

interface LevelProps extends HTMLDefaultProps, BulmaProps {}

export const Level: FunctionComponent<LevelProps> = (props) => (
  <nav {...mapBulmaProps(props, 'level')} />
)

interface LevelLeftProps extends HTMLDivProps, BulmaProps {}

export const LevelLeft: FunctionComponent<LevelLeftProps> = (props) => (
  <div {...mapBulmaProps(props, 'level-left')} />
)

interface LevelRightProps extends HTMLDivProps, BulmaProps {}

export const LevelRight: FunctionComponent<LevelRightProps> = (props) => (
  <div {...mapBulmaProps(props, 'level-right')} />
)

interface LevelItemProps extends HTMLDivProps, BulmaProps {}

export const LevelItem: FunctionComponent<LevelItemProps> = (props) => (
  <div {...mapBulmaProps(props, 'level-item')} />
)

interface HeadingParagraphProps extends HTMLParagraphProps, BulmaProps {}

export const HeadingParagraph: FunctionComponent<HeadingParagraphProps> = (
  props,
) => <p {...mapBulmaProps(props, 'heading')} />

interface TitleParagraphProps extends HTMLParagraphProps, BulmaProps {}

export const TitleParagraph: FunctionComponent<TitleParagraphProps> = (
  props,
) => <p {...mapBulmaProps(props, 'title')} />

interface ColumnsProps extends HTMLDivProps, BulmaProps {}

export const Columns: FunctionComponent<ColumnsProps> = (props) => (
  <div {...mapBulmaProps(props, 'columns')} />
)

interface ColumnProps extends HTMLDivProps, BulmaProps {}

export const Column: FunctionComponent<ColumnProps> = (props) => (
  <div {...mapBulmaProps(props, 'column')} />
)
