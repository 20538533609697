import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import {
  mapBulmaProps,
  BulmaProps,
  HTMLDefaultProps,
  HTMLDivProps,
  HTMLParagraphProps,
  HTMLAnchorProps,
} from './base'

export interface CardProps extends HTMLDivProps, BulmaProps {}

export const Card: FunctionComponent<CardProps> = (props) => (
  <div {...mapBulmaProps(props, 'card')} />
)

export interface CardHeaderProps extends HTMLDefaultProps, BulmaProps {}

export const CardHeader: FunctionComponent<CardHeaderProps> = (props) => (
  <header {...mapBulmaProps(props, 'card-header')} />
)

export interface CardHeaderTitleProps extends HTMLParagraphProps, BulmaProps {}

export const CardHeaderTitle: FunctionComponent<CardHeaderTitleProps> = (
  props,
) => <p {...mapBulmaProps(props, 'card-header-title')} />

export interface CardContentProps extends HTMLDivProps, BulmaProps {}

export const CardContent: FunctionComponent<CardContentProps> = (props) => (
  <div {...mapBulmaProps(props, 'card-content')} />
)

export interface CardFooterProps extends HTMLDefaultProps, BulmaProps {}

export const CardFooter: FunctionComponent<CardFooterProps> = (props) => (
  <footer {...mapBulmaProps(props, 'card-footer')} />
)

export interface CardFooterItemProps extends HTMLAnchorProps, BulmaProps {
  loading?: boolean
}

const CardFooterItemBase: FunctionComponent<CardFooterItemProps> = ({
  ...other
}) => <a {...mapBulmaProps(other, 'card-footer-item')} />

export const CardFooterItem = styled((props) => (
  <CardFooterItemBase {...props} />
))`
  ${(props) =>
    props.loading &&
    css`
      color: transparent !important;
      pointer-events: none;

      &:after {
        /* common */
        -webkit-animation: spinAround 0.5s infinite linear;
        animation: spinAround 0.5s infinite linear;
        border: 2px solid #dbdbdb;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 1em;
        position: relative;
        width: 1em;

        /* button */
        position: absolute;
      }
    `}
`
