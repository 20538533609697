import { FC } from 'react'
import { Badge, IconButton } from '@material-ui/core'
import Link from 'next/link'
import styled from 'styled-components'
import { AlarmBorderIcon } from 'svgIcons'

interface IFHeaderNotificationIconProps {
  notificationsUnreadCount?: number
}

const HeaderNotificationIcon: FC<IFHeaderNotificationIconProps> = ({
  notificationsUnreadCount,
}) => {
  return (
    <StyledWrapper>
      <Link href="/notifications">
        <a>
          <IconButton className="icon-button">
            <Badge
              badgeContent={notificationsUnreadCount}
              overlap="rectangular"
              color="secondary"
            >
              <AlarmBorderIcon className="alarm-icon" />
            </Badge>
          </IconButton>
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default HeaderNotificationIcon

const StyledWrapper = styled.div`
  .icon-button {
    padding: 8px;
    .alarm-icon {
      width: 20px;
      height: 20px;
      & g {
        stroke: #fff;
      }
    }
  }
`
