import { FunctionComponent } from 'react'
import {
  mapBulmaProps,
  BulmaProps,
  HTMLDefaultProps,
  HTMLDivProps,
} from './base'

interface MessageProps extends HTMLDefaultProps, BulmaProps {}

export const Message: FunctionComponent<MessageProps> = (props) => (
  <article {...mapBulmaProps(props, 'message')} />
)

interface MessageHeaderProps extends HTMLDivProps, BulmaProps {}

export const MessageHeader: FunctionComponent<MessageHeaderProps> = (props) => {
  const { children, ...other } = mapBulmaProps(props, 'message-header')

  return (
    <div {...other}>
      <p>{children}</p>
    </div>
  )
}

interface MessageBodyProps extends HTMLDivProps, BulmaProps {}

export const MessageBody: FunctionComponent<MessageBodyProps> = (props) => (
  <div {...mapBulmaProps(props, 'message-body')} />
)
