import { FunctionComponent } from 'react'
import { mapBulmaProps, BulmaProps, HTMLHeadingProps } from './base'

export interface TitleProps extends HTMLHeadingProps, BulmaProps {}

// Titles

export const Title1: FunctionComponent<TitleProps> = (props) => (
  <h1 {...mapBulmaProps(props, 'title is-1')} />
)

export const Title2: FunctionComponent<TitleProps> = (props) => (
  <h2 {...mapBulmaProps(props, 'title is-2')} />
)

export const Title3: FunctionComponent<TitleProps> = (props) => (
  <h3 {...mapBulmaProps(props, 'title is-3')} />
)

export const Title4: FunctionComponent<TitleProps> = (props) => (
  <h4 {...mapBulmaProps(props, 'title is-4')} />
)

export const Title5: FunctionComponent<TitleProps> = (props) => (
  <h5 {...mapBulmaProps(props, 'title is-5')} />
)

export const Title6: FunctionComponent<TitleProps> = (props) => (
  <h6 {...mapBulmaProps(props, 'title is-6')} />
)

// Subtitles

export const Subtitle1: FunctionComponent<TitleProps> = (props) => (
  <h1 {...mapBulmaProps(props, 'subtitle is-1')} />
)

export const Subtitle2: FunctionComponent<TitleProps> = (props) => (
  <h2 {...mapBulmaProps(props, 'subtitle is-2')} />
)

export const Subtitle3: FunctionComponent<TitleProps> = (props) => (
  <h3 {...mapBulmaProps(props, 'subtitle is-3')} />
)

export const Subtitle4: FunctionComponent<TitleProps> = (props) => (
  <h4 {...mapBulmaProps(props, 'subtitle is-4')} />
)

export const Subtitle5: FunctionComponent<TitleProps> = (props) => (
  <h5 {...mapBulmaProps(props, 'subtitle is-5')} />
)

export const Subtitle6: FunctionComponent<TitleProps> = (props) => (
  <h6 {...mapBulmaProps(props, 'subtitle is-6')} />
)
