import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { mapBulmaProps, BulmaProps, HTMLProgressProps } from './base'

export enum Size {
  SMALL,
  MEDIUM,
  LARGE,
}

function getSizeClassName(size: Size | undefined) {
  switch (size) {
    case Size.SMALL: {
      return 'is-small'
    }
    case Size.MEDIUM: {
      return 'is-medium'
    }
    case Size.LARGE: {
      return 'is-large'
    }
    default: {
      return null
    }
  }
}

export interface ProgressBarProps extends HTMLProgressProps, BulmaProps {
  size?: Size
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = (props) => {
  const { size, ...other } = props

  return (
    <progress
      {...mapBulmaProps(other, classNames('progress', getSizeClassName(size)))}
    />
  )
}
