import { FunctionComponent, useState } from 'react'
import fetch from 'cross-fetch'
import { Field, Control, Input, Button } from '.'
import { LINKAREER_API_BASE_URL } from '../utils/config'

export const LoginBox: FunctionComponent = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      const body = { username, password }
      await fetch(`${LINKAREER_API_BASE_URL}/login`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
      })

      setUsername('')
      setPassword('')
    } catch (error) {
      throw new Error(error as any)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field>
        <Control>
          <Input
            className="is-small"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="type username"
          />
        </Control>
        <Control>
          <Input
            className="is-small"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="type password"
          />
        </Control>
        <Control>
          <Button className="is-small" type="submit">
            Log in
          </Button>
        </Control>
      </Field>
    </form>
  )
}
