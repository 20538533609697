import { FC } from 'react'
import { IconButton } from '@material-ui/core'
import Link from 'next/link'
import styled from 'styled-components'
import CalendarIcon from 'svgIcons/Calendar'

const HeaderCalendarIcon: FC = () => {
  return (
    <StyledWrapper>
      <Link href="/calendar">
        <a>
          <IconButton className="icon-button">
            <CalendarIcon className="calendar-icon" />
          </IconButton>
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default HeaderCalendarIcon

const StyledWrapper = styled.div`
  .icon-button {
    padding: 8px;
    .calendar-icon {
      width: 20px;
      height: 20px;
      & g {
        stroke: #fff;
      }
    }
  }
`
