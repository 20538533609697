import { FunctionComponent } from 'react'
import classNames from 'classnames'

interface IconProps {
  icon: string
  small?: boolean
}

export const Icon: FunctionComponent<IconProps> = ({ icon, small }) => (
  <span
    className={classNames('icon', {
      'is-small': small,
    })}
  >
    <i className={icon} />
  </span>
)
