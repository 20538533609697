import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { mapBulmaProps, BulmaProps, HTMLTableProps } from './base'

export interface TableProps extends HTMLTableProps, BulmaProps {
  striped?: boolean
}

export const Table: FunctionComponent<TableProps> = (props) => {
  const { striped, ...other } = props

  const className = classNames(other.className, {
    'is-bordered': striped,
  })

  return <table {...mapBulmaProps({ ...other, className }, 'table')} />
}
