import { FunctionComponent } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import { UrlObject } from 'url'
import {
  mapBulmaProps,
  BulmaProps,
  HTMLDefaultProps,
  HTMLDivProps,
  HTMLAnchorProps,
} from './base'

export interface NavbarProps extends HTMLDefaultProps, BulmaProps {}

export const Navbar: FunctionComponent<NavbarProps> = (props) => (
  <nav role="navigation" {...mapBulmaProps(props, 'navbar')} />
)

export interface NavbarBrandProps extends HTMLDivProps, BulmaProps {}

export const NavbarBrand: FunctionComponent<NavbarBrandProps> = (props) => (
  <div {...mapBulmaProps(props, 'navbar-brand')} />
)

export interface NavbarBurgerProps extends HTMLAnchorProps, BulmaProps {
  active?: boolean
}

export const NavbarBurger: FunctionComponent<NavbarBurgerProps> = ({
  active,
  ...other
}) => (
  <a
    {...mapBulmaProps(
      {
        ...other,
        className: classNames(other.className, {
          'is-active': active,
        }),
      },
      'navbar-burger burger',
    )}
  />
)

export interface NavbarStartProps extends HTMLDivProps, BulmaProps {}

export const NavbarStart: FunctionComponent<NavbarStartProps> = (props) => (
  <div {...mapBulmaProps(props, 'navbar-start')} />
)

export interface NavbarEndProps extends HTMLDivProps, BulmaProps {}

export const NavbarEnd: FunctionComponent<NavbarEndProps> = (props) => (
  <div {...mapBulmaProps(props, 'navbar-end')} />
)

export interface NavbarItemProps extends HTMLDivProps, BulmaProps {}

export const NavbarItem: FunctionComponent<NavbarItemProps> = (props) => (
  <div {...mapBulmaProps(props, 'navbar-item')} />
)

export interface LinkNavbarItemProps extends HTMLDivProps, BulmaProps {
  href: UrlObject | string
}

export const LinkNavbarItem: FunctionComponent<LinkNavbarItemProps> = (
  props,
) => (
  <div {...mapBulmaProps(props, 'navbar-item')}>
    <Link href={props.href}>
      <a>{props.children}</a>
    </Link>
  </div>
)

export interface NavbarMenuProps extends HTMLDivProps, BulmaProps {
  active?: boolean
}

export const NavbarMenu: FunctionComponent<NavbarMenuProps> = ({
  active,
  ...other
}) => (
  <div
    {...mapBulmaProps(
      {
        ...other,
        className: classNames(other.className, {
          'is-active': active,
        }),
      },
      'navbar-menu',
    )}
  />
)
