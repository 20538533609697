import { FC } from 'react'
import styled, { css } from 'styled-components'
import { cloudinaryURL } from 'utils/cloudinary'

type TProps = {
  width: number
  height: number
  src: string
}

const AdvertiseBannerPresenter: FC<TProps> = ({ width, height, src }) => {
  return (
    <StyledWrapper width={width} height={height}>
      <figure>
        <figcaption>광고 배너 이미지</figcaption>
        <img
          className="image-ad"
          src={cloudinaryURL(src)}
          alt="advertise banner"
        />
      </figure>
    </StyledWrapper>
  )
}

export default AdvertiseBannerPresenter

const StyledWrapper = styled.div<{ width: number; height: number }>`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
  display: inline-block;
  cursor: pointer;

  figure {
    width: 100%;
    height: 100%;

    figcaption {
      font-size: 0;
    }

    .image-ad {
      border-radius: 5px;
      vertical-align: bottom;
    }
  }
`
