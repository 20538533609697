import { FC } from 'react'
import Link from 'next/link'
import LinkareerLogoWithName from 'svgIcons/LinkareerLogoWithName'

interface IFHeaderLogoProps {
  className?: string
  noColor?: boolean
}

const HeaderLogo: FC<IFHeaderLogoProps> = ({ className, noColor }) => {
  return (
    <Link href="/">
      <a>
        <LinkareerLogoWithName className={className} noColor={noColor} />
      </a>
    </Link>
  )
}

export default HeaderLogo
