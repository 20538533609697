import { FC } from 'react'
import { Badge, IconButton } from '@material-ui/core'
import Link from 'next/link'
import styled from 'styled-components'
import { BookMarkBorderIcon } from 'svgIcons'

interface IFHeaderScrapIconProps {
  scrapCount?: number | null
}

const HeaderScrapIcon: FC<IFHeaderScrapIconProps> = ({ scrapCount }) => {
  return (
    <StyledWrapper>
      <Link href="/accounts/scrap">
        <a>
          <IconButton className="icon-button">
            <Badge
              badgeContent={scrapCount}
              color="secondary"
              overlap="rectangular"
            >
              <BookMarkBorderIcon className="bookmark-icon" />
            </Badge>
          </IconButton>
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default HeaderScrapIcon

const StyledWrapper = styled.div`
  .icon-button {
    padding: 8px;
    .bookmark-icon {
      width: 20px;
      height: 20px;
      & g,
      & path {
        stroke: #fff !important;
      }
    }
  }
`
