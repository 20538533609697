import { CSSProperties, FC } from 'react'
import { CircularProgress } from '@material-ui/core'
import Link from 'next/link'
import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'
import useCurrentAD from 'hooks/useCurrentAD'
import AdvertiseBannerPresenter from '../component/AdvertiseBannerPresenter'

type TProps = {
  code: string
  placeholder?: {
    width: number
    height: number
    otherStyles?: CSSProperties
  }
  disableLoadingProgress?: boolean
  className?: string
}

const AdvertiseBanner: FC<TProps> = ({
  code,
  placeholder,
  disableLoadingProgress = false,
  className,
}) => {
  const { loading, ad } = useCurrentAD({ code })

  const noAd = !loading && !ad.isActive
  const doNotShowLoading = loading && disableLoadingProgress

  if (noAd || doNotShowLoading) {
    return null
  }

  if (loading) {
    return (
      <StyledWrapper className={className}>
        {placeholder ? (
          <Skeleton width={placeholder.width} height={placeholder.height} />
        ) : (
          <CircularProgress className="loading" />
        )}
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper className={className}>
      <Link href={ad.landingURL || ''}>
        <a target="_blank">
          <AdvertiseBannerPresenter
            width={ad.width}
            height={ad.height}
            src={ad.adImageURL || ''}
          />
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default AdvertiseBanner

const StyledWrapper = styled.div`
  display: inline-block;
  background-color: transparent;

  .loading {
    width: 100%;
    height: 100%;
  }
`
