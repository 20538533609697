import { useMemo } from 'react'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import { useCurrentAdAndPlacementByCodeQuery } from 'generated/graphql'

interface UseCurrentADInfo {
  isActive: boolean
  adImageURL?: string
  landingURL?: string
  width: number
  height: number
}

interface UseCurrentADData {
  loading: boolean
  ad: UseCurrentADInfo
}

const useCurrentAD = ({ code }: { code: string }): UseCurrentADData => {
  const currentTime = useMemo(() => {
    const currentDate = new Date()
    return format(currentDate, 'HH:mm:ss')
  }, [])

  const { data, loading } = useCurrentAdAndPlacementByCodeQuery({
    variables: {
      code,
      currentTime,
    },
  })

  const currentAd = data?.currentAdByPlacementCodeAndCurrentTime
  const placement = data?.adPlacementByCode

  const hasDefaultImageAndLandingURL =
    !!placement?.defaultImage && !!placement?.defaultLandingURL

  let isActive = true
  if (!currentAd) {
    isActive = false
  }

  if (placement?.isActive && hasDefaultImageAndLandingURL) {
    isActive = true
  }

  const landingURL = `/advertisement?adPlacementID=${placement?.id}&campaignID=${currentAd?.campaign?.id}`
  const adImageURL = currentAd?.image?.url || placement?.defaultImage?.url

  const width = placement?.width ?? 0
  const height = placement?.height ?? 0

  const ad = {
    isActive,
    adImageURL,
    landingURL,
    width,
    height,
  }

  return {
    loading,
    ad,
  }
}

export default useCurrentAD

gql`
  query CurrentAdAndPlacementByCode($code: String!, $currentTime: String!) {
    currentAdByPlacementCodeAndCurrentTime(
      code: $code
      currentTime: $currentTime
    ) {
      image {
        url
      }
      campaign {
        id
        landingURL
      }
    }
    adPlacementByCode(code: $code) {
      id
      isActive
      width
      height
      defaultImage {
        url
      }
      defaultLandingURL
    }
  }
`
