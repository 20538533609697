import { FunctionComponent, HTMLAttributes } from 'react'
import {
  mapBulmaProps,
  BulmaProps,
  HTMLDefaultProps,
  HTMLDivProps,
} from './base'

export interface BoxProps extends HTMLDivProps, BulmaProps {}

export const Box: FunctionComponent<BoxProps> = (props) => (
  <div {...mapBulmaProps(props, 'box')} />
)

export interface MediaProps extends HTMLDefaultProps, BulmaProps {}

export const Media: FunctionComponent<MediaProps> = (props) => (
  <article {...mapBulmaProps(props, 'media')} />
)

export interface MediaLeftProps extends HTMLDivProps, BulmaProps {}

export const MediaLeft: FunctionComponent<MediaLeftProps> = (props) => (
  <figure {...mapBulmaProps(props, 'media-left')} />
)

export interface MediaContentProps
  extends HTMLAttributes<HTMLDivElement>,
    BulmaProps {}

export const MediaContent: FunctionComponent<MediaContentProps> = (props) => (
  <div {...mapBulmaProps(props, 'media-content')} />
)

export interface MediaRightProps extends HTMLDivProps, BulmaProps {}

export const MediaRight: FunctionComponent<MediaRightProps> = (props) => (
  <figure {...mapBulmaProps(props, 'media-right')} />
)

export interface ContentProps
  extends HTMLAttributes<HTMLDivElement>,
    BulmaProps {}

export const Content: FunctionComponent<ContentProps> = (props) => (
  <div {...mapBulmaProps(props, 'content')} />
)
