import { FunctionComponent } from 'react'
import { mapBulmaProps, BulmaProps, HTMLDivProps, HTMLSpanProps } from './base'

export interface TagsProps extends HTMLDivProps, BulmaProps {}

export const Tags: FunctionComponent<TagsProps> = (props) => (
  <div {...mapBulmaProps(props, 'tags')} />
)

export interface TagProps extends HTMLSpanProps, BulmaProps {}

export const Tag: FunctionComponent<TagProps> = (props) => (
  <span {...mapBulmaProps(props, 'tag')} />
)
