import { FunctionComponent } from 'react'
import {
  mapBulmaProps,
  BulmaProps,
  HTMLDivProps,
  HTMLDefaultProps,
} from './base'

export interface HeroProps extends HTMLDefaultProps, BulmaProps {}

export const Hero: FunctionComponent<HeroProps> = (props) => (
  <section {...mapBulmaProps(props, 'hero')} />
)

export interface HeroBodyProps extends HTMLDivProps, BulmaProps {}

export const HeroBody: FunctionComponent<HeroBodyProps> = (props) => (
  <div {...mapBulmaProps(props, 'hero-body')} />
)
