import { FC, useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { Button } from '@material-ui/core'
import { BaseModalContainer } from 'components'
import { addDays } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import styled from 'styled-components'
import { DYNAMIC_LINK } from 'app.constants/firebaseUrls'
import CloseIcon from 'svgIcons/Close'
import { TIMEZONE } from 'utils/config'

type TProps = {
  isOpen: boolean
  onModalClose: () => void
}

const UPSELL_MODAL_NO_VIEWING_COOKIE_NAME = 'lnkr_upsell_modal_no_viewing'

const UpsellAppModal: FC<TProps> = ({ isOpen, onModalClose }) => {
  const [cookies, setCookie] = useCookies()

  const isModalHideCookieExpired =
    !!cookies?.[UPSELL_MODAL_NO_VIEWING_COOKIE_NAME]

  const getCookieDomain = () => {
    const url = window.location.href
    if (url.includes('linkareer')) return '.linkareer.com'
    if (url.includes('lnkr')) return '.lnkr.cc'
    return 'localhost'
  }

  const handleSetCookie = (expiresDate: Date) => {
    setCookie(UPSELL_MODAL_NO_VIEWING_COOKIE_NAME, Date.now(), {
      expires: expiresDate,
      domain: getCookieDomain(),
      path: '/',
    })
  }

  const handleModalClose = useCallback(() => {
    const afterOneDays = zonedTimeToUtc(addDays(new Date(), 1), TIMEZONE)
    handleSetCookie(afterOneDays)
    onModalClose()
  }, [onModalClose])

  const handleNoViewingFor7DaysClick = useCallback(() => {
    const after7Days = zonedTimeToUtc(addDays(new Date(), 7), TIMEZONE)
    handleSetCookie(after7Days)
    onModalClose()
  }, [onModalClose])

  return (
    <BaseModalContainer isOpen={!isModalHideCookieExpired && isOpen}>
      <StyledWrapper>
        <i>
          <CloseIcon
            role="button"
            onClick={handleModalClose}
            data-gtm-category="continue-mobile-web-button"
          />
        </i>
        <img src="/static/images/appDeepLinkImg.png" alt="app modal logo" />
        <strong>링커리어 앱에서는</strong>
        <p>
          공고와 취준 정보를
          <br />
          <mark className="primary-point">더 빠르고 편하게</mark> 볼수 있어요!
        </p>
        <Button
          className="deep-link-button"
          href={DYNAMIC_LINK.mainHome}
          id="try-the-app-button"
          data-gtm-category="try-the-app-button"
        >
          앱으로 편하게 보기
        </Button>
        <a
          className="continue-mobile-web"
          role="button"
          onClick={handleNoViewingFor7DaysClick}
          id="continue-mobile-web-button"
          data-gtm-category="continue-mobile-web-button"
        >
          오늘은 그냥 볼게요
        </a>
      </StyledWrapper>
    </BaseModalContainer>
  )
}

export default UpsellAppModal

const StyledWrapper = styled.div`
  width: 100%;
  height: 352px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > i {
    position: absolute;
    top: 24px;
    right: 20px;
    > svg {
      display: block;
      width: 16px;
      height: 16px;
      fill: #999999;
    }
  }

  > img {
    height: 100px;
    margin-top: 36px;
  }

  > strong {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.27;
    letter-spacing: -0.66px;
    text-align: center;
    color: #01a0ff;
    margin: 8px 0px 3px;
  }

  > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.48px;
    text-align: center;
    color: #333;

    .primary-point {
      color: #01a0ff;
      background-color: transparent;
    }
  }

  > .deep-link-button {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    background-color: #01a0ff;
    border-radius: 5px;
    margin: 15px 0px;
    width: calc(100% - 20px);
    height: 50px;
    box-shadow: none;
    &:hover {
      background-color: #01a0ff;
      color: #fff;
      box-shadow: none;
    }
  }

  > .continue-mobile-web {
    color: #999999;
    font-size: 13px;
    font-weight: normal;
    line-height: 2.31;
    letter-spacing: -0.52px;
    text-align: center;
  }
`
