import { FunctionComponent, ReactNode, forwardRef } from 'react'
import classNames from 'classnames'
import {
  mapBulmaProps,
  BulmaProps,
  HTMLDivProps,
  HTMLLabelProps,
  HTMLInputProps,
  HTMLTextareaProps,
} from './base'
import { Button, ButtonProps, AnchorButton, AnchorButtonProps } from './button'

interface FieldProps extends HTMLDivProps, BulmaProps {
  label?: ReactNode
  labelFor?: string
  hasAddons?: boolean
  horizontal?: boolean
}

export const Field: FunctionComponent<FieldProps> = (props) => {
  const {
    label,
    labelFor,
    hasAddons,
    horizontal,
    className,
    children,
    ...other
  } = mapBulmaProps(props, 'field')

  return (
    <div
      {...other}
      className={classNames(className, {
        'has-addons': hasAddons,
        'is-horizontal': horizontal,
      })}
    >
      {label && <Label htmlFor={labelFor}>{label}</Label>}
      {children}
    </div>
  )
}

interface LabelProps extends HTMLLabelProps, BulmaProps {}

export const Label: FunctionComponent<LabelProps> = (props) => (
  <label {...mapBulmaProps(props, 'label')} />
)

interface FieldLabelProps extends HTMLDivProps, BulmaProps {}

export const FieldLabel: FunctionComponent<FieldLabelProps> = (props) => (
  <div {...mapBulmaProps(props, 'field-label')} />
)

interface FieldBodyProps extends HTMLDivProps, BulmaProps {}

export const FieldBody: FunctionComponent<FieldBodyProps> = (props) => (
  <div {...mapBulmaProps(props, 'field-body')} />
)

interface ControlProps extends HTMLDivProps, BulmaProps {}

export const Control: FunctionComponent<ControlProps> = (props) => (
  <div {...mapBulmaProps(props, 'control')} />
)

interface InputProps extends HTMLInputProps, BulmaProps {}

export const Input: FunctionComponent<InputProps> = (props) => (
  <input {...mapBulmaProps(props, 'input')} />
)

type InputControlProps = InputProps

export const InputControl: FunctionComponent<InputControlProps> = (props) => {
  const { id, className, children, ...other } = props

  return (
    <Control id={id} className={className}>
      <Input {...other} />
      {children}
    </Control>
  )
}

interface TextareaProps extends HTMLTextareaProps, BulmaProps {
  // innerRef: Ref<HTMLTextAreaElement>
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    return <textarea {...mapBulmaProps(props, 'textarea')} ref={ref} />
  },
)

type TextareaControlProps = TextareaProps

export const TextareaControl: FunctionComponent<TextareaControlProps> = (
  props,
) => {
  const { id, className, ...other } = props

  return (
    <Control id={id} className={className}>
      <Textarea {...other} />
    </Control>
  )
}

type ButtonControlProps = ButtonProps

export const ButtonControl: FunctionComponent<ButtonControlProps> = (props) => {
  const { id, className, ...other } = props

  return (
    <Control id={id} className={className}>
      <Button {...other} />
    </Control>
  )
}

type AnchorButtonControlProps = AnchorButtonProps

export const AnchorButtonControl: FunctionComponent<
  AnchorButtonControlProps
> = (props) => {
  const { id, className, ...other } = props

  return (
    <Control id={id} className={className}>
      <AnchorButton {...other} />
    </Control>
  )
}
